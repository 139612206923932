"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Animation = void 0;
var react_1 = __importStar(require("react"));
var inversify_react_1 = require("inversify-react");
var lodash_1 = require("lodash");
var mobx_react_1 = require("mobx-react");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var BlockStyleStore_1 = require("Project/Stores/BlockStyleStore");
exports.Animation = (0, mobx_react_1.observer)(function () {
    var blockStore = (0, inversify_react_1.useInjection)("blockStore");
    var telemetry = (0, inversify_react_1.useInjection)("telemetry");
    var hasPreviewBeenFiredRef = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(function () {
        sendTelemetry("Block Styles Animation Viewed");
        return function () {
            blockStore.turnOffAnimation();
        };
    }, []);
    var blockStyleStore = blockStore.blockStyleStore;
    var blockStyleModel = (0, BlockStyleStore_1.getNonSourceBlockStyle)(blockStyleStore);
    var animation = blockStyleModel.animation;
    if (!animation)
        return null;
    var animationOption = animation.animationOption, animationType = animation.animationType, direction = animation.direction, speed = animation.speed;
    function handleChange(settingKey, newValue) {
        if (!animation)
            return;
        var newAnimation = (0, lodash_1.cloneDeep)(animation);
        newAnimation[settingKey] = newValue;
        blockStyleStore.setStylePropsByKey("animation", newAnimation);
        blockStore.previewAnimation();
    }
    function sendTelemetry(name, parameters) {
        telemetry.trackOnly(name, parameters || {}, {
            blockIntercom: true,
        });
    }
    return (react_1.default.createElement(kaleidoscope_1.Stack, { paddingBottom: "s", gap: "m" },
        react_1.default.createElement(kaleidoscope_1.Select, { label: "Animation", value: animationOption, onChange: lodash_1.noop, autoStack: true }, animationOptions.map(function (_a) {
            var id = _a.id, label = _a.label;
            return (react_1.default.createElement(kaleidoscope_1.SelectOption, { key: id, value: id, onClick: function () {
                    handleChange("animationOption", id);
                    sendTelemetry("Block Styles Animation Changed", {
                        block_styles_animation_option: id,
                    });
                } }, label));
        })),
        animationOption !== commonTypes_1.AnimationOption.None && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(kaleidoscope_1.SegmentedControl, { label: "Type", options: animationTypeOptions, selectedValue: animationType, onClickHandler: function (newValue) {
                    handleChange("animationType", newValue);
                    sendTelemetry("Block Styles Animation Changed", {
                        block_styles_animation_type: newValue,
                    });
                } }),
            animationType === commonTypes_1.AnimationType.Slide && (react_1.default.createElement(kaleidoscope_1.SegmentedControl, { label: "Direction", options: directionOptions, selectedValue: direction, onClickHandler: function (newValue) {
                    handleChange("direction", newValue);
                    sendTelemetry("Block Styles Animation Changed", {
                        block_styles_animation_direction: newValue,
                    });
                } })),
            react_1.default.createElement(kaleidoscope_1.SegmentedControl, { label: "Speed", options: speedOptions, selectedValue: speed, onClickHandler: function (newValue) {
                    handleChange("speed", newValue);
                    sendTelemetry("Block Styles Animation Changed", {
                        block_styles_animation_speed: newValue,
                    });
                } }),
            react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary, width: "full", size: kaleidoscope_1.ButtonSize.Small, onClick: function () {
                    if (!hasPreviewBeenFiredRef.current) {
                        sendTelemetry("Block Styles Animation Previewed");
                        hasPreviewBeenFiredRef.current = true;
                    }
                    blockStore.previewAnimation();
                } }, "Preview")))));
});
var animationOptions = [
    {
        id: commonTypes_1.AnimationOption.None,
        label: "No animation",
    },
    {
        id: commonTypes_1.AnimationOption.Section,
        label: "Animate by section",
    },
    {
        id: commonTypes_1.AnimationOption.Together,
        label: "Animate together",
    },
];
var animationTypeOptions = [
    {
        label: "Fade in",
        value: commonTypes_1.AnimationType.Fade,
    },
    {
        label: "Slide in",
        value: commonTypes_1.AnimationType.Slide,
    },
];
var directionOptions = [
    {
        value: commonTypes_1.AnimationDirection.Left,
        label: react_1.default.createElement(icons_1.ArrowLeft, null),
    },
    {
        value: commonTypes_1.AnimationDirection.Right,
        label: react_1.default.createElement(icons_1.ArrowRight, null),
    },
    {
        value: commonTypes_1.AnimationDirection.Up,
        label: react_1.default.createElement(icons_1.ArrowUp, null),
    },
    {
        value: commonTypes_1.AnimationDirection.Down,
        label: react_1.default.createElement(icons_1.ArrowDown, null),
    },
];
var speedOptions = [
    {
        value: commonTypes_1.AnimationSpeed.Slow,
        label: "Slow",
    },
    {
        value: commonTypes_1.AnimationSpeed.Medium,
        label: "Med",
    },
    {
        value: commonTypes_1.AnimationSpeed.Fast,
        label: "Fast",
    },
];
