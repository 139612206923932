"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.variables = void 0;
exports.variables = {
    colorPrimary: "#230D7B",
    colorText: "#181C46",
    colorBackground: "#FAFCFD",
    buttonPrimaryColorBackground: "#230D7B",
    buttonPrimaryColorBorder: "#FAFCFD",
    buttonPrimaryColorText: "#F1F0FE",
    buttonSecondaryColorBackground: "#FFFFFF",
    buttonSecondaryColorBorder: "#D1D7E2",
    buttonSecondaryColorText: "#3E456D",
    colorSecondaryText: "#3E456D",
    actionPrimaryColorText: "#230D7B",
    actionPrimaryTextDecorationLine: "underline",
    actionPrimaryTextDecorationStyle: "solid",
    actionSecondaryTextDecorationStyle: "solid",
    colorBorder: "#D1D7E2",
    formHighlightColorBorder: "#B2B5C2",
    formAccentColor: "#1099FC",
    colorDanger: "#DE2B4A",
    badgeSuccessColorBackground: "#EFFBFB",
    badgeSuccessColorBorder: "#FAFCFD",
    badgeSuccessColorText: "#1D7E8A",
    badgeWarningColorBackground: "#FFF3EA",
    badgeWarningColorBorder: "#FAFCFD",
    badgeWarningColorText: "#AA5918",
    badgeDangerColorBackground: "#FDEAEE",
    badgeDangerColorBorder: "#FAFCFD",
    badgeDangerColorText: "#B52B5B",
    offsetBackgroundColor: "#FAFCFD",
    formBackgroundColor: "#FFFFFF",
    fontFamily: "calibre-legacy, sans-serif",
    fontSizeBase: "16px",
    headingXlFontSize: "24px",
    headingLgFontSize: "24px",
    headingMdFontSize: "24px",
    headingXsFontSize: "16px",
    bodyMdFontSize: "16px",
    bodySmFontSize: "16px",
    labelMdFontSize: "16px",
    labelMdFontWeight: "300",
};
