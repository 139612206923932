"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var OverlayStack_1 = require("OverlayStack/OverlayStack");
var ProjectViewStore = (function () {
    function ProjectViewStore(overlayStack) {
        this.overlayStack = overlayStack;
        this._promptForBackgroundBlockId = null;
    }
    Object.defineProperty(ProjectViewStore.prototype, "currentOverlay", {
        get: function () {
            return this.overlayStack.currentCard;
        },
        enumerable: false,
        configurable: true
    });
    ProjectViewStore.prototype.showOverlay = function (newCard) {
        this.overlayStack.add(newCard);
    };
    ProjectViewStore.prototype.closeCurrentOverlay = function () {
        this.overlayStack.closeCurrent();
    };
    ProjectViewStore.prototype.closeAllOverlays = function () {
        throw new Error("Not Implemented!!!");
    };
    Object.defineProperty(ProjectViewStore.prototype, "promptForBackgroundBlockId", {
        get: function () {
            return this._promptForBackgroundBlockId;
        },
        set: function (blockId) {
            this._promptForBackgroundBlockId = blockId;
        },
        enumerable: false,
        configurable: true
    });
    ProjectViewStore.prototype.clearPromptForBackgroundBlockId = function () {
        this._promptForBackgroundBlockId = null;
    };
    var _a;
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ProjectViewStore.prototype, "currentOverlay", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_a = typeof OverlayStack_1.IOverlayCardConfig !== "undefined" && OverlayStack_1.IOverlayCardConfig) === "function" ? _a : Object]),
        __metadata("design:returntype", void 0)
    ], ProjectViewStore.prototype, "showOverlay", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ProjectViewStore.prototype, "closeCurrentOverlay", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ProjectViewStore.prototype, "closeAllOverlays", null);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], ProjectViewStore.prototype, "_promptForBackgroundBlockId", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [String])
    ], ProjectViewStore.prototype, "promptForBackgroundBlockId", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ProjectViewStore.prototype, "clearPromptForBackgroundBlockId", null);
    return ProjectViewStore;
}());
exports.default = ProjectViewStore;
