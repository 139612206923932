import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVXW/aMBR951dcIVUiG66SAG2aqo/7A3ubpqpyYgfcGju1HQqd+t+nOA7EDkzrJl7I9bn3XJ/74eunRLD34p0k8GsCUEvNDJMiB0U5NmxH7ycAG8rWG5NDEsdX95OPyXXvlOZ5QSupqHUupTBUmBym03svFi605I2xsTitTA5xvfcCr5zhjRGz6YkAjMJCV1Jt8+4vx4b+mCELj7xMFnmOK0PV3ydy5M4ct5eZl8iAZ2kJLp2uhqcVM8hl4oFuLIgwXXN8yIEJzgRFBZflyzAv3BjZ+v0k2GCkKadlewmkmFg/dtdslJYqh1oyYag6ge0nLtvyoXcp6OPcBWn1Q0IS+jClnG6pMFM/FKEVbrg5hXIwZA41fZgaujffzjq2J94tiT1GqP8uXQlrrKwgw7PsKPvJdnfGho+28yUdCQLAhKYmhxLzcjbbYTUbMETwFXwTjiL4Aijxe4t8vssLuUd6g4l8yyG2v7RLvJS8zdCnjeeBoYwiGwWXL2slG0GQ8wsktJ+O3iJcHNIobJtFPz0lBb99fk6SJnKHFGsm1kgbLAhW5ITQ0XxE+Z8BByUIle5kUoQqpDBhjQ4hRahKFvV1UQ0uSpVC0G3HmRJSUK+EdNyNal3M0sUK0mUM6fLWL3l1Hp+s7iBNlpCulj5+fQF/A8lqAekq9eEsgGf93TtBvls97PJQkp/0XEejGcnOzMiytQ3YXtxqt0OB6I4Ko3uNjuVBIy+v6x0qPt+WYfeGIzCqx/ZzO/DoJ8c62xTQlu1nTADHxdwqn2WQZG2hFhEk8dV8ODl+Lep/CZn+MaQO3tLhZjCyDvVSVi/VXdluqvZhGS2n1yhg+fxasuRdDaUxcus+upevf3Td8xWSXxq97rKyxiUzBxew5xlkawKZVQhoxgCUxEFT7gLQaw43AeRtDEnC3j6MMXaQPn4Df7AVUg8JAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWTXObMBC9+1doMpMZM7VcRIrtKKfeeu6tJ4+QZFCiSIwkbMed/vcOAgLyV9qQmdyQ9oO3b98uzNeILUv1tErA7wkApbbCCa0wIJnVsnL8YQLATjBXYLAlZgphFxBHtcnpEoO4fsq0c/q5OxD6lBtdKYaBybMpWgCU3oEkTcBXEM+T1AcfoFCM7zFAD/7dQjluIN9y5SwGSiveJDaMG2gIE5XtUDSXP/0dtJXZEMrXa5TJ5eMjQoVPr0tChXtpITlDVFdca2lzscqQ+h7KPsMuao2cWKFyaB1RjBjWe9hoNihzZKqHyZ/JfI1MRTJqEjAPu8KELSV56Sjxro39m7f3hc5XgXnhzW80I42CmKWPkXzjPHEDy8pbjMiLE9N9GHTBixx7+Z5stHnGzaMkjt+xaSg0FM1APANxCDP7wFzM5/oHgdi+Z9V/CgRSLbUZmfBU1VcHFq3KfX0seNON7nw0USiOb+trWhmrDe4G8dpMvk5uEhKJM77Rhns+qVaOK4fBzc1lpH5/3DWwmma2h66E5KiE5GoJx3y3WiiJ4cpdGjL2xpBtwiE7u6yEEk4QGcbhIR2n0HaFcIMNhwEq98BqKbpt4v1gzaPR8julXLkfestNL4lF1MTvoS0I07tuPTYnyCXfeqGhPoSF2s/fWVw+trg1UuKQHVg8u1xtjzqNRldaeKT9LqAYUCLpdFnuwZcjUPdRGCtOY+GyVuLA53GwkXzio91Do5PXkCgCsFZ8/y1NGyGf22a/pjCNb6PhqPIdNNyKQyjYp+Gi/lwoslmtdbp3ARlBZwdM7Sw/B+15JLQx/L5i4/YsbaqZqvaX6tOYu4BOj0f3IeQFjf0LxEV8nsoKAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var snapAreaWidth = 'var(--_1d7pnk80)';
export var snapAreaXOffset = 'var(--_1d7pnk81)';
export var resizeableSnapArea = _7a468({defaultClassName:'_1d7pnk82',variantClassNames:{visible:{false:'_1d7pnk83',true:'_1d7pnk84'},highlighted:{false:'_1d7pnk85',true:'_1d7pnk86'}},defaultVariants:{},compoundVariants:[]});
export var resizeableSnapAreaContentStart = '_1d7pnk87';
export var resizeableSnapAreaContentEnd = '_1d7pnk88';
export var resizableSnapAreaContent = '_1d7pnk89';
export var snapAreaWide = '_1d7pnk8a';
export var snapAreaFull = '_1d7pnk8b';
export var resizeHandle = _7a468({defaultClassName:'_1d7pnk8d',variantClassNames:{state:{idle:'_1d7pnk8e',hover:'_1d7pnk8f',active:'_1d7pnk8g'},inset:{true:'_1d7pnk8h',false:'_1d7pnk8i'},position:{left:'_1d7pnk8j',right:'_1d7pnk8k',topLeft:'_1d7pnk8l',topRight:'_1d7pnk8m',bottomLeft:'_1d7pnk8n',bottomRight:'_1d7pnk8o'}},defaultVariants:{},compoundVariants:[]});