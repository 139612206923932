import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVXW/aMBR951dcIVUiG66SAG2aqo/7A3ubpqpyYgfcGju1HQqd+t+nOA7EDkzrJl7I9bn3XJ/74eunRLD34p0k8GsCUEvNDJMiB0U5NmxH7ycAG8rWG5NDEsdX95OPyXXvlOZ5QSupqHUupTBUmBym03svFi605I2xsTitTA5xvfcCr5zhjRGz6YkAjMJCV1Jt8+4vx4b+mCELj7xMFnmOK0PV3ydy5M4ct5eZl8iAZ2kJLp2uhqcVM8hl4oFuLIgwXXN8yIEJzgRFBZflyzAv3BjZ+v0k2GCkKadlewmkmFg/dtdslJYqh1oyYag6ge0nLtvyoXcp6OPcBWn1Q0IS+jClnG6pMFM/FKEVbrg5hXIwZA41fZgaujffzjq2J94tiT1GqP8uXQlrrKwgw7PsKPvJdnfGho+28yUdCQLAhKYmhxLzcjbbYTUbMETwFXwTjiL4Aijxe4t8vssLuUd6g4l8yyG2v7RLvJS8zdCnjeeBoYwiGwWXL2slG0GQ8wsktJ+O3iJcHNIobJtFPz0lBb99fk6SJnKHFGsm1kgbLAhW5ITQ0XxE+Z8BByUIle5kUoQqpDBhjQ4hRahKFvV1UQ0uSpVC0G3HmRJSUK+EdNyNal3M0sUK0mUM6fLWL3l1Hp+s7iBNlpCulj5+fQF/A8lqAekq9eEsgGf93TtBvls97PJQkp/0XEejGcnOzMiytQ3YXtxqt0OB6I4Ko3uNjuVBIy+v6x0qPt+WYfeGIzCqx/ZzO/DoJ8c62xTQlu1nTADHxdwqn2WQZG2hFhEk8dV8ODl+Lep/CZn+MaQO3tLhZjCyDvVSVi/VXdluqvZhGS2n1yhg+fxasuRdDaUxcus+upevf3Td8xWSXxq97rKyxiUzBxew5xlkawKZVQhoxgCUxEFT7gLQaw43AeRtDEnC3j6MMXaQPn4Df7AVUg8JAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var ringDataAttribute = 'data-selection-ring';
export var interactiveElementSelector = '[data-interactive-zone]';
export var textElementSelector = '[data-element-type="textElement"]';
export var childSelector = '[data-slate-node="element"]';
export var selectionRingWidth = 2;
export var outsideRingOffset = 8;
export var customRingColor = 'var(--_1nizbzd0)';
export var wrapper = _7a468({defaultClassName:'_1nizbzd1',variantClassNames:{extraSpaceBeforeFirstWidgetInBlock:{true:'_1nizbzd2'},extraSpaceAfterLastWidgetInBlock:{true:'_1nizbzd3'},widgetWidth:{'full-width':'_1nizbzd4','fit-widget':'_1nizbzd5'},isInline:{true:'_1nizbzd6',false:'_1nizbzd7'}},defaultVariants:{},compoundVariants:[]});
export var ringToWidgetOffset = 'var(--_1nizbzd9)';
export var ringToHitboxInset = 'var(--_1nizbzda)';
export var customRingBorderRadius = 'var(--_1nizbzdb)';
export var ring = _7a468({defaultClassName:'_1nizbzdd',variantClassNames:{mouseOverWidget:{true:'_1nizbzde'},mouseOverSelectableRegion:{true:'_1nizbzdf'},selected:{true:'_1nizbzdg'},mode:{hugging:'_1nizbzdh',outside:'_1nizbzdi'},nodeType:{'void':'_1nizbzdj',textElement:'_1nizbzdk',layoutElement:'_1nizbzdl'},isInline:{true:'_1nizbzdm',false:'_1nizbzdn'}},defaultVariants:{},compoundVariants:[[{mouseOverSelectableRegion:true,nodeType:'textElement'},'_1nizbzdo'],[{selected:true,nodeType:'textElement'},'_1nizbzdp']]});
export var dragHandle = _7a468({defaultClassName:'_1nizbzds',variantClassNames:{alignment:{widget:'_1nizbzdt','selection-ring':'_1nizbzdu'},mode:{hugging:'_1nizbzdv',outside:'_1nizbzdw'},nodeType:{'void':'_1nizbzdx',textElement:'_1nizbzdy',layoutElement:'_1nizbzdz'}},defaultVariants:{},compoundVariants:[]});