import "Application/Project/QwilrPay/QwilrPay.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrPay/QwilrPay.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+VV246bMBB936+YPqwUJBxhBxJCf6JqP2BlsAPOGhsZZ0NV7b9Xxgk4zWW36mMfeGDOmTPDHA9eHntWbnkCv54ASm0YNwXgboBeS8Hg6xRFlZbaFPBGzQL5F+SRlxdcys1+j3ESBXxDmTj0BaTd4KJUilohYXnbF1BxZblx4Y4yJlRdAHG096el7wf7fmj1Wht9UOxW9f5gdrTiP3ilFaPm59SHeNCGfuNmJ/WxgEYwxlVQk4w1j4LZpoAsuehnBctXiSQtuRxZO60sOnJRN7YAnCQBNQ1lcJI8u7LNTB3fr7/M1OViu4YtAbzBUaCX+YJCSk8iWQanJ6SlRcl32vA7g7uUhy+i7bSxVNlAYu0rST6g2rgR4cAhJPnOng3oO1q5WD97L8NmNqPS5O3DpCunPNsHv48xVGlljZZzYh1Wy8dqLR3Qeep54t3+WPl0iGblJlTe3vLys5/VUlMLhazu/qQOQ0Deh+RSW6vbD/lTg3Rs8MZyWD7Yb0a04V6sw8zywUEhKYmBpKsYSJZG4VkJMwqQQnFqUO3my5VdYJwzXsdOg44iToNkaYwjSJ6neHqKr1wckwnIcOyT1g4g6QwkPsMDq3wGnJSXwxFk2SWwGeVwBBt8CeQxSRMH5IFUFpN0ewac15dbMi/CTUODs9lFIfueo1cJ/3pWq08b+p/ZeGO46/D3ENx3f3/dTeNn9zZRVFpNOV2Ywh8t7/W1lkV379H333J71HzHBwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var partPaymentInputGroup = 'wsdb9e0';
export var partPaymentTypeToggle = 'wsdb9e1';
export var toggleButton = 'wsdb9e2';
export var paymentOptionToggle = 'wsdb9e3';
export var popoverIconButton = 'wsdb9e4';
export var qwilrPayIcon = 'wsdb9e5';
export var paymentTypeInput = 'wsdb9e6';
export var previewTooltips = 'wsdb9e7';
export var tooltipImage = 'wsdb9e8';
export var qPayVideoPlaceholder = 'wsdb9e9';
export var activateHeading = 'wsdb9ea';
export var integrationPrompt = 'wsdb9eb';
export var previewWrapper = 'wsdb9ec';
export var previewIcon = 'wsdb9ed';
export var previewTextWrapper = 'wsdb9ee';