import "Application/Library/PaymentsDashboard/PaymentSettings/PaymentsSettings.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/PaymentsDashboard/PaymentSettings/PaymentsSettings.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA82VzY6bMBDH7/sUc6m0OTjCjkkCfYv2ASIHG+KssZExG9Kq715hAjjJls1KOfRoPB+/+c+MWe5wUrXmLYLfLwAnyd0hBRxF376/AByELA5uOP95WQ7Gy9JwprxLyVo0uFEaVW3nWDHOpS5SuJydaB1iShY6BSVyFwbDn2cOAr4z+4pQXbFM6gK1arfDe7U5HjE2i85wbywXFtmLe9VCbZTkwVVmlLFDIH9A/c0UKlqEgOSpgOZd2FyZUwqsccZzseytsKbR/EO28XaM9OsKbzWHt1nfNOQaL6DTnq6w0lM0pU6hrpgGEki3N86Z8kmyUs/9GNjkFT/SDC7rSrFz6ssZy3KirBRz4lJfnUK0XOUWouUmt/dW1py8SRTnFnBnMVGsPcX9VD88B1OoTVhQ7Pdlutz2Kyb1sGKU9P28OQ4CrJL+PGwB47KpB5b+4w//DWVGO2sCqiJYn6DDYIv963YLeEuBxKvFxwPbGZEVBkJjIHG8uGpCroSH8koh6URZp5AJ7YRX/djUTuZnTyS0m64mGZL/QwacbAEnayA0mdOBdjpEQGL6bB3YqMNQaUyu9hs5U92OXj0Vpxah7bDNs+ZT8r1P/q+X4AuLnwWBwghff0zuOkyrOYXH3byIFvy6CL3ZPN5Dzj7NdWNzlomfIjOaM3se4eQM2/T+HyTnQodJhU/azQkqbGeCw351T8xn3foLIVw2/M8HAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var paymentsModal = '_19pxok0';
export var leftPanelWrapper = '_19pxok1';
export var rightPanelWrapper = '_19pxok2';
export var modalHeader = '_19pxok3';
export var modalContainer = '_19pxok4';
export var modalContent = '_19pxok5';
export var settingsCard = '_19pxok6';
export var toggleButton = '_19pxok7';
export var generalSettingsIconStyle = '_19pxok8';
export var permissionsIconStyle = '_19pxok9';
export var settingsRow = _7a468({defaultClassName:'_19pxoka',variantClassNames:{hasDivider:{true:'_19pxokb'}},defaultVariants:{},compoundVariants:[]});
export var partPaymentInputGroup = '_19pxokc';
export var partPaymentTypeToggle = '_19pxokd';
export var paymentTypeInput = '_19pxoke';