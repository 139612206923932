"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoneScreen = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var react_1 = __importStar(require("react"));
var styles = __importStar(require("./DoneScreen.css"));
var DoneScreen = function (_a) {
    var onComplete = _a.onComplete;
    (0, react_1.useEffect)(function () {
        setTimeout(function () {
            onComplete();
        }, 1000);
    }, [onComplete]);
    return (react_1.default.createElement(kaleidoscope_1.Stack, { align: "center", justify: "center", gap: "xs", direction: "horizontal" },
        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.doneIconContainer, align: "center", justify: "center" },
            react_1.default.createElement(icons_1.Tick, { color: "success" })),
        react_1.default.createElement(kaleidoscope_1.Heading, { className: styles.doneText, level: "3", strong: true }, "Done!")));
};
exports.DoneScreen = DoneScreen;
