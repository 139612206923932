"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorStrap = exports.DocUploader = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var react_1 = __importStar(require("react"));
var styles = __importStar(require("./DocUploader.css"));
var icons_1 = require("@qwilr/kaleidoscope/icons");
var UploadScreen_1 = require("./UploadScreen");
var ProgressScreen_1 = require("./ProgressScreen");
var DoneScreen_1 = require("./DoneScreen");
var DocUploader = function (_a) {
    var apiBaseUrl = _a.apiBaseUrl, handleEndDocUpload = _a.handleEndDocUpload;
    var _b = __read((0, react_1.useState)("start"), 2), currentStep = _b[0], setCurrentStep = _b[1];
    var _c = __read((0, react_1.useState)(null), 2), file = _c[0], setFile = _c[1];
    var _d = __read((0, react_1.useState)(""), 2), generatedProject = _d[0], setGeneratedProject = _d[1];
    return (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.container, align: "center", justify: "center", direction: "vertical" },
        currentStep === "start" && (react_1.default.createElement(UploadScreen_1.UploadScreen, { onUpload: function (file) {
                setFile(file);
                setCurrentStep("processing");
            } })),
        currentStep === "processing" && (react_1.default.createElement(ProgressScreen_1.ProgressScreen, { file: file, apiBaseUrl: apiBaseUrl, onComplete: function (projectId) {
                setGeneratedProject(projectId);
                setCurrentStep("done");
            }, onCancel: function () {
                setFile(null);
                setGeneratedProject("");
                setCurrentStep("start");
            } })),
        currentStep === "done" && (react_1.default.createElement(DoneScreen_1.DoneScreen, { onComplete: function () {
                handleEndDocUpload(generatedProject);
            } }))));
};
exports.DocUploader = DocUploader;
var ErrorStrap = function (_a) {
    var text = _a.text;
    return (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.errorStrap, direction: "horizontal", gap: "xs", align: "center", justify: "center", padding: "xs" },
        react_1.default.createElement(icons_1.InformationCircle, { color: "caution" }),
        text));
};
exports.ErrorStrap = ErrorStrap;
