"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var TEXT_ELEMENTS = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "li",
    "table",
    ".quote-section",
    ".quote-total",
    "blockquote",
];
function generateCSS(fontSelections) {
    var e_1, _a, e_2, _b;
    var _c;
    var css = "";
    var fallbackFont = (_c = fontSelections.find(function (s) { return s.fallback; })) === null || _c === void 0 ? void 0 : _c.font;
    var remainingTargets = new Set(TEXT_ELEMENTS);
    try {
        for (var fontSelections_1 = __values(fontSelections), fontSelections_1_1 = fontSelections_1.next(); !fontSelections_1_1.done; fontSelections_1_1 = fontSelections_1.next()) {
            var selection = fontSelections_1_1.value;
            var tag = selection.tag, font = selection.font, className = selection.className;
            var selector = tag;
            if (className) {
                selector = "".concat(selector, ".").concat(className);
                remainingTargets.delete(className);
            }
            else {
                remainingTargets.delete(tag);
            }
            var selectors = [
                ".project-block content-area ".concat(selector),
                ".project-block .new-editor ".concat(selector),
                ".style-editor .project-block ".concat(selector),
                ".census-collector__project-preview .project-block ".concat(selector),
            ].join(", ");
            css += "".concat(selectors, " {  font-family: '").concat(font.name, "', sans-serif;  }");
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (fontSelections_1_1 && !fontSelections_1_1.done && (_a = fontSelections_1.return)) _a.call(fontSelections_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    css +=
        ".qwilr-font, .user-font .qwilr-font, .user-font .calibre { font-family: 'calibre-legacy', sans-serif !important; }";
    if (!fallbackFont)
        return css;
    try {
        for (var remainingTargets_1 = __values(remainingTargets), remainingTargets_1_1 = remainingTargets_1.next(); !remainingTargets_1_1.done; remainingTargets_1_1 = remainingTargets_1.next()) {
            var selector = remainingTargets_1_1.value;
            var selectors = [
                ".project-block content-area ".concat(selector, ":not(.icon)"),
                ".project-block .new-editor ".concat(selector, ":not(.icon)"),
                ".style-editor .project-block ".concat(selector, ":not(.icon)"),
                ".user-font ".concat(selector),
            ].join(", ");
            css += "".concat(selectors, " { font-family: '").concat(fallbackFont.name, "', sans-serif !important; }");
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (remainingTargets_1_1 && !remainingTargets_1_1.done && (_b = remainingTargets_1.return)) _b.call(remainingTargets_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    css += ".user-font { font-family: '".concat(fallbackFont.name, "', sans-serif !important; }");
    css += ".project-block .widget-adder__placeholder p { font-family: '".concat(fallbackFont.name, "', sans-serif !important;  }");
    return css;
}
exports.default = generateCSS;
