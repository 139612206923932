"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutStylesContextProvider = exports.useStylesContext = exports.StylesEditorContextProvider = void 0;
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var inversify_react_1 = require("inversify-react");
var mobx_react_1 = require("mobx-react");
var react_1 = __importStar(require("react"));
var StylesContext = react_1.default.createContext(undefined);
exports.StylesEditorContextProvider = (0, mobx_react_1.observer)(function (_a) {
    var children = _a.children;
    var blockStore = (0, inversify_react_1.useInjection)("blockStore");
    var brandStore = (0, inversify_react_1.useInjection)("brandStore");
    var backgroundColor;
    var maybeAssignBackgroundColor = function (color) {
        var bgColorAsIColor = (0, GetBlockStyle_1.convertColorObjToColor)(color);
        var sanitisedColor = (0, GetBlockStyle_1.sanitizeColor)(bgColorAsIColor, brandStore.colorsWithThemeIndex);
        if (sanitisedColor) {
            backgroundColor = sanitisedColor.value;
        }
    };
    switch (blockStore.blockType) {
        case BlockTypes_1.BlockType.Text: {
            var bgColorRule = blockStore.blockStyleStore.getElementStyle("background");
            if ("type" in bgColorRule) {
                if (bgColorRule.type === "color") {
                    maybeAssignBackgroundColor(bgColorRule);
                }
                else {
                    if (bgColorRule.card.enabled) {
                        maybeAssignBackgroundColor(bgColorRule.card.color);
                    }
                    else {
                        backgroundColor = "#000000";
                    }
                }
            }
            else {
                maybeAssignBackgroundColor(bgColorRule);
            }
            break;
        }
        case BlockTypes_1.BlockType.Source:
        case BlockTypes_1.BlockType.Video:
        case BlockTypes_1.BlockType.Accept:
        case BlockTypes_1.BlockType.Quote: {
            var bgColorRule = blockStore.blockStyleStore.getElementStyle("background");
            maybeAssignBackgroundColor(bgColorRule);
            break;
        }
        case BlockTypes_1.BlockType.Splash: {
            backgroundColor = "#000000";
            break;
        }
        case BlockTypes_1.BlockType.Agreement: {
            backgroundColor = "#FFFFFF";
            break;
        }
    }
    if (!backgroundColor) {
        backgroundColor = "#FFFFFF";
    }
    return react_1.default.createElement(StylesContext.Provider, { value: { backgroundColor: backgroundColor } }, children);
});
var useStylesContext = function () {
    var context = (0, react_1.useContext)(StylesContext);
    if (!context) {
        throw new Error("Cannot call useStylesContext outside of a StylesContext.Provider");
    }
    return context;
};
exports.useStylesContext = useStylesContext;
var LayoutStylesContextProvider = function (_a) {
    var backgroundColor = _a.backgroundColor, computeForegroundColor = _a.computeForegroundColor, children = _a.children;
    var stylesContext = (0, exports.useStylesContext)();
    return (react_1.default.createElement(StylesContext.Provider, { value: __assign(__assign(__assign({}, stylesContext), (!!backgroundColor ? { backgroundColor: backgroundColor } : {})), { computeForegroundColor: computeForegroundColor }) }, children));
};
exports.LayoutStylesContextProvider = LayoutStylesContextProvider;
