"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var CommonActions = __importStar(require("./CommonActions"));
var slate_1 = require("slate");
var Utils_1 = require("../Utils");
var Paragraph_1 = require("../WidgetRegistry/Widgets/Paragraph");
var GenericText_1 = require("@CommonFrontend/WidgetRegistry/CommonWidgets/GenericText");
exports.default = (function (di) {
    return {
        onShiftEnter: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("commonOnShiftEnter")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Enter)
            .withModifier(BehaviourBuilder_1.KeyModifier.Shift)
            .inBlockType(__spreadArray(["blockQuote", "listItem"], __read(GenericText_1.ALL_GENERIC_TEXT_TYPES), false))
            .doAction(function (event, editor) {
            event.preventDefault();
            CommonActions.singleLineBreak(editor);
            return true;
        }),
        onEnter: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("commonOnEnter")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Enter)
            .inBlockType(__spreadArray(["blockQuote"], __read(GenericText_1.ALL_HEADINGS), false))
            .atEndOfBlock()
            .doAction(function (event, editor) {
            event.preventDefault();
            CommonActions.newParagraphBelow(editor);
            return true;
        }),
        onEnterAtStartOfSelection: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onEnterAtStartOfSelection")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Enter)
            .inBlockType(__spreadArray(__spreadArray([], __read(GenericText_1.ALL_HEADINGS), false), ["blockQuote"], false))
            .atStartOfBlock()
            .doAction(function (event, editor) {
            var _a;
            event.preventDefault();
            var selectionPath = (_a = editor.selection) === null || _a === void 0 ? void 0 : _a.anchor.path;
            if (!selectionPath)
                return;
            var nodePath = slate_1.Path.parent(selectionPath);
            slate_1.Transforms.insertNodes(editor, (0, Paragraph_1.createParagraphNode)(), { at: nodePath });
            return true;
        }),
        onBackspaceEmptySingleton: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("commonBackspaceEmptySingleton")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .inBlockType(__spreadArray(["blockQuote"], __read(GenericText_1.ALL_HEADINGS), false))
            .inFirstBlock()
            .inLastBlock()
            .inEmptyBlock()
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_1.Transforms.setNodes(editor, { type: "paragraph" });
            return true;
        }),
        onBackspaceEmptyFirstLine: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onBackspaceEmptyFirstLine")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .inBlockType(__spreadArray(__spreadArray([], __read(GenericText_1.ALL_GENERIC_TEXT_TYPES), false), ["blockQuote", "listItem"], false))
            .inFirstElementofContainer()
            .inEmptyBlock()
            .doAction(function (event, editor) {
            var _a;
            event.preventDefault();
            var selectionPath = (_a = editor.selection) === null || _a === void 0 ? void 0 : _a.anchor.path;
            if (!selectionPath)
                return;
            var nodePath = slate_1.Path.parent(selectionPath);
            var replaceNode = (0, Utils_1.isElementOnlyNodeInContainer)(editor, nodePath);
            slate_1.Editor.withoutNormalizing(editor, function () {
                if (replaceNode) {
                    slate_1.Transforms.setNodes(editor, (0, Paragraph_1.createParagraphNode)(), { at: nodePath });
                }
                else {
                    slate_1.Transforms.removeNodes(editor, { at: nodePath });
                }
            });
            return true;
        }),
        onBackspaceWithRangedSelection: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("backspaceWithRangedSelection")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .selectionIsExpanded()
            .doAction(function (event, editor) {
            event.preventDefault();
            CommonActions.deleteSelectedRange(editor, "backward");
            return true;
        }),
        onDeleteWithRangedSelection: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("deleteWithRangedSelection")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Delete)
            .selectionIsExpanded()
            .doAction(function (event, editor) {
            event.preventDefault();
            CommonActions.deleteSelectedRange(editor, "forward");
            return true;
        }),
        onPlaceCursorInTrailingParagraph: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("placeCursorInTrailingParagraph")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "any", action: "placeCursorInTrailingParagraph" })
            .doAction(function (_event, editor) {
            CommonActions.placeCursorInTrailingParagraph(editor);
            return true;
        }),
    };
});
