"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableBackgroundProvider = void 0;
var react_1 = __importStar(require("react"));
var pages_common_1 = require("@qwilr/pages-common");
var StylesContext_1 = require("Project/QwilrEditor/StylesContext");
var inversify_react_1 = require("inversify-react");
var TableWidgetContext_1 = require("./TableWidgetContext");
var FilterableColorProvider_1 = require("Project/QwilrEditor/Styling/FilterableColorProvider");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var TableBackgroundProvider = function (_a) {
    var children = _a.children, isTableHeader = _a.isTableHeader;
    var tableContext = (0, react_1.useContext)(TableWidgetContext_1.TableWidgetContext);
    var backgroundColor = getBackgroundColor(tableContext.style, isTableHeader);
    var blockStore = (0, inversify_react_1.useInjection)("blockStore");
    var colorProvider = (0, FilterableColorProvider_1.useFilterableColorProvider)();
    return (react_1.default.createElement(StylesContext_1.LayoutStylesContextProvider, { backgroundColor: backgroundColor, computeForegroundColor: function () {
            var blockPColor = blockStore.blockStyleStore.getElementStyle("p").color;
            var decoratedParagraphColor = colorProvider.decorateColor((0, GetBlockStyle_1.convertColorObjToColor)(blockPColor)).value;
            if (backgroundColor) {
                return (0, pages_common_1.goldilocks)(backgroundColor, { mode: "text", foregroundColor: decoratedParagraphColor });
            }
            return decoratedParagraphColor;
        } }, children));
};
exports.TableBackgroundProvider = TableBackgroundProvider;
var getBackgroundColor = function (tableStyle, isTableHeader) {
    if (isTableHeader && tableStyle.header.on) {
        return tableStyle.header.color.abs;
    }
    if (tableStyle.background.on) {
        return tableStyle.background.color.abs;
    }
    return undefined;
};
