"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./QwilrPay.css"));
var QwilrPayMenu_1 = require("./QwilrPayMenu");
var PaymentsOnboarding_1 = require("Library/PaymentsDashboard/PaymentsOnboarding");
var Configuration_1 = require("Styles/Configuration/Configuration");
var inversify_react_1 = require("inversify-react");
var AcceptPreviewEventEmitter_1 = require("../Modules/Accept/Controls/AcceptPreviewEventEmitter");
var DIProvider_1 = __importStar(require("DIProvider/DIProvider"));
var inversify_1 = require("inversify");
var usePaymentGateways_1 = require("Library/PaymentsDashboard/PaymentSettings/usePaymentGateways");
var QwilrPayButton = (function () {
    function QwilrPayButton(createUi, mountReactComponent, di, projectStore) {
        this.ui = createUi(require("Project/QwilrPay/QwilrPayButton.html"), {
            $init: function (scope) {
                var pageId = projectStore._id;
                scope.ui.qwilrPayButton = mountReactComponent(react_1.default.createElement(DIProvider_1.RootDIProvider, { di: di },
                    react_1.default.createElement(DIProvider_1.default, { container: function () {
                            var container = new inversify_1.Container({ skipBaseClassChecks: true });
                            container.bind("acceptPreviewEventEmitter").toConstantValue(di.get("acceptPreviewEventEmitter"));
                            return container;
                        } },
                        react_1.default.createElement(QwilrPayButtonComponent, { pageId: pageId }))));
            },
        });
    }
    return QwilrPayButton;
}());
exports.default = QwilrPayButton;
var QwilrPayButtonComponent = function (_a) {
    var pageId = _a.pageId;
    var acceptPreviewEventEmitter = (0, inversify_react_1.useInjection)("acceptPreviewEventEmitter");
    var gateways = (0, usePaymentGateways_1.usePaymentGateway)().gateways;
    var _b = __read((0, react_1.useState)(false), 2), isOnboardingModalOpen = _b[0], setIsOnboardingModalOpen = _b[1];
    var showAcceptPreview = function () {
        acceptPreviewEventEmitter.trigger(AcceptPreviewEventEmitter_1.AcceptPreviewEvent.OpenPreview);
    };
    return (react_1.default.createElement(kaleidoscope_1.Layer, { offset: Configuration_1.blockMenuZIndex }, function () { return (react_1.default.createElement(react_1.default.Fragment, null,
        !gateways[0] && (react_1.default.createElement(PaymentsOnboarding_1.PaymentsOnboarding, { showModal: isOnboardingModalOpen, closeModal: function () { return setIsOnboardingModalOpen(false); } })),
        react_1.default.createElement(kaleidoscope_1.Popover, { padding: "none", size: kaleidoscope_1.PopoverSize.Large, position: kaleidoscope_1.PopoverPosition.Bottom, onClickStopPropagation: true, button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.IconButton, __assign({ size: kaleidoscope_1.ButtonSize.Medium, isRound: true, className: styles.popoverIconButton, icon: react_1.default.createElement(icons_1.QwilrPay, { className: styles.qwilrPayIcon }), tooltip: { content: "Qwilr Pay" }, buttonState: kaleidoscope_1.ButtonState.Idle }, buttonProps))); }, caret: true, autoStack: true },
            react_1.default.createElement(QwilrPayMenu_1.QwilrPayMenu, { pageId: pageId, gateways: gateways || [], setIsOnboardingModalOpen: setIsOnboardingModalOpen, showPreviewCallback: showAcceptPreview })))); }));
};
