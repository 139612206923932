"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LightDarkThemeProvider = exports.useLightDarkThemeContext = void 0;
var react_1 = __importStar(require("react"));
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var LightDarkThemeContext = (0, react_1.createContext)({
    theme: "light",
    lightDarkClassName: tokens_1.lightTheme,
});
var useLightDarkThemeContext = function () {
    return (0, react_1.useContext)(LightDarkThemeContext);
};
exports.useLightDarkThemeContext = useLightDarkThemeContext;
var LightDarkThemeProvider = function (_a) {
    var theme = _a.theme, children = _a.children;
    var lightDarkClassName = theme === "light" ? tokens_1.lightTheme : tokens_1.darkTheme;
    return (react_1.default.createElement(LightDarkThemeContext.Provider, { value: { theme: theme, lightDarkClassName: lightDarkClassName } }, children({ lightDarkClassName: lightDarkClassName })));
};
exports.LightDarkThemeProvider = LightDarkThemeProvider;
