"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextTypeMenu = void 0;
var react_1 = __importStar(require("react"));
var inversify_react_1 = require("inversify-react");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var EditorTypes_1 = require("Project/QwilrEditor/EditorTypes");
var EditorWidgetRegistry_1 = require("Project/QwilrEditor/WidgetRegistry/EditorWidgetRegistry");
var UseTextWidgetsOrder_1 = require("Project/QwilrEditor/WidgetRegistry/Utils/UseTextWidgetsOrder");
var styles = __importStar(require("./MenuStyles.css"));
var TextTypeMenu = function (_a) {
    var selectedTextWidgets = _a.selectedTextWidgets, containerElementType = _a.containerElementType, onToolbarEvent = _a.onToolbarEvent;
    var optionsOrder = (0, UseTextWidgetsOrder_1.useTextWidgetsOrder)();
    var editor = (0, inversify_react_1.useInjection)(EditorTypes_1.SlateEditor.$);
    var editorName = editor.schema.name;
    var _b = __read((0, react_1.useState)(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var validOptions = optionsOrder
        .filter(function (option) {
        var config = (0, EditorWidgetRegistry_1.getConfigByType)(option);
        var isValidContainer = containerElementType === null || config.validContainers.includes(containerElementType);
        return config.validEditors.includes(editorName) && isValidContainer;
    })
        .map(function (option) { return ({
        textType: option,
        isInSelection: selectedTextWidgets.some(function (w) { return w.widgetConfig.type === option; }),
    }); });
    if (validOptions.length <= 1) {
        return null;
    }
    var _c = getMenuButtonContent(validOptions), menuIcon = _c.menuIcon, menuText = _c.menuText;
    return (react_1.default.createElement(kaleidoscope_1.ToolbarPopover, { className: tokens_1.darkTheme, size: kaleidoscope_1.PopoverSize.Auto, padding: "none", button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.Button, __assign({ className: styles.textTypeMenuButton, type: kaleidoscope_1.ButtonType.Tertiary }, buttonProps),
            menuIcon,
            menuText)); }, autoStack: true, isOpen: isOpen, onChange: setIsOpen },
        react_1.default.createElement(kaleidoscope_1.Stack, { padding: "xxs", gap: "xxs" }, validOptions.map(function (_a) {
            var textType = _a.textType, isInSelection = _a.isInSelection;
            var _b = (0, EditorWidgetRegistry_1.getConfigByType)(textType), icon = _b.icon, displayName = _b.displayName;
            return (react_1.default.createElement(kaleidoscope_1.Button, { className: styles.menuOptionButton, key: textType, type: kaleidoscope_1.ButtonType.Tertiary, width: "full", onClick: function () {
                    onToolbarEvent({ button: textType });
                    setIsOpen(false);
                }, "aria-pressed": isInSelection },
                icon,
                displayName));
        }))));
};
exports.TextTypeMenu = TextTypeMenu;
function getMenuButtonContent(validOptions) {
    var optionsInSelection = validOptions.filter(function (_a) {
        var isInSelection = _a.isInSelection;
        return isInSelection === true;
    });
    if (optionsInSelection.length === 1 &&
        !!optionsInSelection[0]) {
        var textType = optionsInSelection[0].textType;
        var _a = (0, EditorWidgetRegistry_1.getConfigByType)(textType), icon_1 = _a.icon, displayName_1 = _a.displayName;
        if (!icon_1 || !displayName_1) {
            throw new Error("Missing icon and/or display name for ".concat(textType));
        }
        return { menuIcon: icon_1, menuText: displayName_1 };
    }
    if (optionsInSelection.length > 1) {
        return { menuIcon: react_1.default.createElement(icons_1.Menu, null), menuText: "Mixed" };
    }
    var _b = (0, EditorWidgetRegistry_1.getConfigByType)("paragraph"), icon = _b.icon, displayName = _b.displayName;
    return { menuIcon: icon, menuText: displayName };
}
